import React from "react"
import styles from "./ref.module.scss"
import Menubar from "../components/menubar/menubarEng"
import Footer from "../components/footer/footerEng"
import TopImage from "../img/ref_1280.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
export default props => (
  <div className={styles.refContainer}>
    <div className={styles.leftE}></div>
    <div className={styles.centerSite}>
      <Menubar />
      <div className={styles.imageTopRefContainer}>
        <img src={TopImage} alt="oyma" className={styles.imageTopRef} />
        <div className={styles.verticalLineWhiteRef}>
          <div className={styles.socialMediaIcons}>
            <a
              href="https://www.facebook.com/atiaahsap"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size="1x"
                className={styles.ffIcon}
              />
            </a>
            <br />
            <a
              href="https://www.instagram.com/atiaahsap/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size="1x"
                className={styles.igIcon}
              />
            </a>
          </div>
        </div>
        <span className={styles.topSpanRefInt}>INTERIOR</span>
        <span className={styles.topSpanRef}>REFERENCES</span>
        <span className={styles.topSpanRefBottom}>
          OUR <span style={{ color: "#977851" }}>REFERENCES</span>
        </span>
      </div>
      <div className={styles.refList}>
        <div className={styles.leftList}>
          <ul>
            <li>ALFEN Construction</li>
            <li>ARC Construction</li>
            <li>ATATEPE Construction </li>
            <li>CORIO Turkey</li>
            <li>DOĞRUER Construction</li>
            <li>GÜRBAĞ Construction</li>
            <li>ÖZOĞUZ Construction</li>
            <li>RG Construction</li>
            <li>TEZELLER Construction</li>
            <li>YEŞİLYURT Iron & Steel</li>
            <li>
              4. Main Base Command Center <br />
              AKINCI - ANKARA
            </li>
            <li>Spain Foreing Trade Undersecretariat Building</li>
            <li>Gendarmerie General Command</li>
            <li>K.İ.K</li>
            <li>KARS Airport</li>
            <li>Private ALTIN Schools</li>
            <li>SAMSUN Airport</li>
            <li>
              T.C. ANKARA Governorship <br />
              Provincial Special Administration
            </li>
            <li>T.C. Ministry of Internal Affairs</li>
            <li>T.C. KIRKLARELI Governorship</li>
            <li>TAI & TUSAŞ H.R.</li>
            <li>TRABZON Airport</li>
            <li>TAF Consolidation Foundation</li>
            <li>312 Architecture</li>
            <li>ABDULLAH GENCASLAN </li>
            <li>ALTAY & ALTAY Architecture </li>
            <li>D'ARCHITECTs DEMAY Architecture</li>
            <li>ÖNCÜOĞLU + ACP Architecture</li>
            <li>TÜMER ONARAN Architecture</li>
          </ul>
        </div>
        <div className={styles.rightList}>
          <ul>
            <li>U DESIGN Architecture</li>
            <li>365 AVM - ANKARA</li>
            <li>DİYARBAKIR 3M MİGROS</li>
            <li>MALLTEPE PARK AVM</li>
            <li>OPTİMUM AVM - ANKARA</li>
            <li>TURKMALL AVM - ŞANLIURFA</li>
            <li>TURKMALL AVM - YOZGAT</li>
            <li>GOKDELEN TOWER - SAMSUN</li>
            <li>NOVA TOWER ÇUKURAMBAR - ANKARA</li>
            <li>SAMTOWER - SAMSUN</li>
            <li>
              SEDA Building <br />
              CUKURAMBAR - ANKARA
            </li>
            <li>TWIN TOWER - SAMSUN</li>
            <li>VADİ PRESTİJ SITE GOP - ANKARA</li>
            <li>VIVENDI ÇAYYOLU - ANKARA</li>
            <li>1071 ANKARA - USTA Construction</li>
            <li>
              H.BAĞIBAN Housing <br />
              ANGORA EVLERİ - ANKARA
            </li>
            <li>I.MENEKŞE Housing ÇANKAYA - ANKARA</li>
            <li>M.AKSU Housing INCEK - ANKARA</li>
            <li>M.AYGAR Housing BEYTEPE - ANKARA</li>
            <li>M.BASTUG Housing ISKENDERUN - HATAY</li>
            <li>
              M.DEMIRBAS Housing SİNPAŞ <br />
              İNCEK LIFE - ANKARA
            </li>
            <li>M.KUMBUZOGLU Housing GÖLBAŞI - ANKARA</li>
            <li>Ö.ARPACI Housing ÇANKAYA - ANKARA</li>
            <li>
              S.DUVEROĞLU Housing <br />
              YESIL BARIS SITE - ANKARA
            </li>
            <li>S.SOYLU Housing BAŞAKŞEHİR - İSTANBUL</li>
            <li>T.YEŞİLYURT Housing İSKENDERUN - HATAY</li>
            <li>U.DEMİRKILIÇ Housing ANGORA EVLERİ - ANKARA</li>
            <li>ÖZBERK Construction BURGAZ 10 VILLA - MUĞLA</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
    <div className={styles.rightE}></div>
  </div>
)
